import './App.css';

function App() {
  return (
    <div className="App">
       <h1>Coming Soon</h1>
    </div>
  );
}

export default App;
